import api from '@/services/api';
import { get } from 'lodash';
import router from '@/router';
import store from '@/store';

// const ERROR_CODE_VALIDATE = 422;
// const ERROR_CODE_NOT_FOUND = 404;
const ERROR_CODE_UNAUTHORIZED = 401;
// const ERROR_CODE_BAD_REQUEST = 400;
// const ERROR_CODE_FORBIDDEN = 403;

export default {
  async get (url, params = {}) {
    try {
      const result = await api.get(url, params);
      const { status, data } = result;
      return {
        status: true,
        code: status,
        data,
      };
    } catch (error) {
      return this.handleError(error);
    }
  },
  async post (url, params = {}) {
    try {
      const result = await api.post(url, params);
      const { status, data } = result;
      return {
        status: true,
        code: status,
        data,
      };
    } catch (error) {
      return this.handleError(error);
    }
  },
  async put (url, params = {}) {
    try {
      const result = await api.put(url, params);
      const { status, data } = result;
      return {
        status: true,
        code: status,
        data,
      };
    } catch (error) {
      return this.handleError(error);
    }
  },
  async patch (url, params = {}) {
    try {
      const result = await api.patch(url, params);
      const { status, data } = result;
      return {
        status: true,
        code: status,
        data,
      };
    } catch (error) {
      return this.handleError(error);
    }
  },
  async delete (url, params = {}) {
    try {
      const result = await api.delete(url, params);
      const { status, data } = result;
      return {
        status: true,
        code: status,
        data,
      };
    } catch (error) {
      console.log('catch', error);
    }
  },
  async download (url, params = {}) {
    try {
      const result = await api.get(url, params, { responseType: 'blob' });
      const { status, data, headers } = result;

      let filename = '';
      const disposition = headers['content-disposition'];
      if (disposition.indexOf('filename*=') !== -1) {
        filename = decodeURIComponent(disposition.split('filename*=')[1].replace('utf-8\'\'', ''));
      } else {
        filename = disposition.split(';')[1].split('filename=')[1].split('"')[1];
      }

      return {
        status: true,
        code: status,
        data,
        headers,
        filename,
      };
    } catch (error) {
      return this.handleError(error);
    }
  },
  async downloadPost (url, params = {}) {
    try {
      const result = await api.post(url, params, { responseType: 'blob' });
      const { status, data, headers } = result;
      return {
        status: true,
        code: status,
        data,
        headers,
      };
    } catch (error) {
      return this.handleError(error);
    }
  },
  handleError (error) {
    console.log('handleError', error);
    const { response, request } = error;
    const { status, data } = response;
    const errorCode = status || 500;

    if (response) {
      this.handleResposeError(response);
    } else if (request) {
      this.handleNoResponse(request);
    } else {
      console.log('Error', error.message);
    }

    return {
      status: false,
      code: errorCode,
      data,
    };
  },
  handleResposeError (response) {
    const { status } = response;
    const errorCode = status || 500;
    switch (errorCode) {
      case ERROR_CODE_UNAUTHORIZED:
        if (get(router, 'history.router.history.current.name') !== 'login') {
          store.commit('userStore/UNSET_USER');
          router.push({ name: 'login' });
        }
        break;

      default:
        console.log('has error');
        break;
    }
  },
  handleNoResponse (request) {
    console.log('handleNoResponse', request);
  },
};

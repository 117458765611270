import apiEndpoints from '@/services/apiEndpoints';
import http from '@/services/http';

export default {
  state: () => {},
  mutations: {},
  getters: {},
  actions: {
    async getListCollection (_, payload) {
      const collectionResponse = await http.get(apiEndpoints.getListCollection, payload);
      if (collectionResponse.status && collectionResponse.data) return collectionResponse.data;
      return [];
    },
    async getCollection (_, id) {
      const collectionResponse = await http.get(apiEndpoints.getCollection.replace('{id}', id), {});
      if (collectionResponse.status && collectionResponse.data) return collectionResponse.data;
      return false;
    },
    async createCollection (_, payload) {
      const collectionResponse = await http.post(apiEndpoints.createCollection, payload);
      if (collectionResponse.status && collectionResponse.data) return collectionResponse;
      return false;
    },
    async updateCollection (_, payload) {
      const id = payload.id;
      delete payload.id;
      const collectionResponse = await http.put(apiEndpoints.updateCollection.replace('{id}', id), payload);
      if (collectionResponse.status) return collectionResponse;
      return false;
    },
    async deleteCollection (_, payload) {
      // const collectionResponse = await http.delete(apiEndpoints.deleteCollection.replace('{id}', payload.id), {});
      // if (collectionResponse.status) return collectionResponse.status;
      // return false;
    },
    async updateStatusDisplay (_, payload) {
      const collectionResponse = await http.patch(apiEndpoints.changeDisplayCollection, payload);
      if (collectionResponse.status) return collectionResponse;
      return false;
    },
    async updateStatusDelivery (_, payload) {
      const collectionResponse = await http.patch(apiEndpoints.changeDeliveryCollection, payload);
      if (collectionResponse.status) return collectionResponse;
      return false;
    },
    async exportCollection (_, payload) {
      const collectionResponse = await http.downloadPost(apiEndpoints.exportCollection, payload);
      return collectionResponse;
    },
    async listGroupSchool (_, payload) {
      const collectionResponse = await http.get(apiEndpoints.getListGroupSchool, {});
      if (collectionResponse.status && collectionResponse.data) return collectionResponse.data;
      return [];
    },
    async listShippingMethod (_, payload) {
      const collectionResponse = await http.get(apiEndpoints.getListShippingMethod, payload);
      if (collectionResponse.status && collectionResponse.data) return collectionResponse.data;
      return [];
    },
    async proposalToDelete (_, id) {
      return await http.post(apiEndpoints.collectionProposalToDelete.replace('{id}', id));
    },
    async confirmToDelete (_, id) {
      return await http.delete(apiEndpoints.collectionConfirmToDelete.replace('{id}', id));
    },
  },
};

export default {
  input: {
    search: '検索',
  },
  label: {
    create: '新規作成',
    setting_fee: '配送料設定',
    require_address: 'お届け先入力',
  },
  table: {
    pattern_name: '配送パターン名',
    method: '発送方法',
    operation: '操作',
  },
  form: {
    pattern_name: '配送パターン名',
    method: '発送方法',
    operation: '操作',
  },
  message: {
    duplicate_pattern: '配送パターン名は既にシステムに存在しています。別の配送パターン名を入力してください。',
    duplicate_fee: '同じ名前の発送方法は設定できません',
    setting_fee: '配送料を設定してください。',
    require_input_price: '配送料を入力してください。',
  },
  setting_fee: {
    fixed: '固定',
    fluctuation: '変動',
    label_region_req: '配送料',
  },
  region: {
    hokkaido: '北海道',
    northeast: '東北',
    kanto: '関東',
    hokushinetsu: '北信越',
    tokai: '東海',
    kansai: '関西',
    china: '中国',
    shikoku: '四国',
    kyushu: '九州',
    okinawa: '沖縄',
  },
  prefecture: {
    hokkaido: '北海道',

    aomori: '青森県',
    miyagi: '宮城県',
    yamagata: '山形県',
    iwate: '岩手県',
    akita: '秋田県',
    fukushima: '福島県',

    tokyo: '東京都',
    kanagawa: '神奈川県',
    chiba: '千葉県',
    yamanashi: '山梨県',
    ibaraki: '茨城県',
    tochigi: '栃木県',
    gunma: '群馬県',
    saitama: '埼玉県',

    niigata: '新潟県',
    nagano: '長野県',
    toyama: '富山県',
    ishikawa: '石川県',
    fukui: '福井県',

    shizuoka: '静岡県',
    gifu: '岐阜県',
    aichi: '愛知県',
    mie: '三重県',

    shiga: '滋賀県',
    hyogo: '兵庫県',
    nara: '奈良県',
    kyoto: '京都府',
    osaka: '大阪府',
    wakayama: '和歌山県',

    tottori: '鳥取県',
    okayama: '岡山県',
    yamaguchi: '山口県',
    shimane: '島根県',
    hiroshima: '広島県',

    kagawa: '香川県',
    tokushima: '徳島県',
    ehime: '愛媛県',
    kochi: '高知県',

    fukuoka: '福岡県',
    nagasaki: '長崎県',
    oita: '大分県',
    saga: '佐賀県',
    kumamoto: '熊本県',
    miyazaki: '宮崎県',
    kagoshima: '鹿児島県',

    okinawa: '沖縄',
  },
};

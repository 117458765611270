import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';
import { cloneDeep } from 'lodash';

const getDefaultState = () => {
  return {
  };
};

const state = getDefaultState();
const getters = {
};
const mutations = {
};
const actions = {
  async getRecommendSizes (_, payload) {
    const response = await http.get(apiEndpoints.getRecommendSizes, payload);
    if (response.status && response.data) return response.data;
    return [];
  },
  async createRecommendSize (_, payload) {
    return await http.post(apiEndpoints.createRecommendSize, payload);
  },
  async deleteRecommendSize (_, payload) {
    const response = await http.delete(apiEndpoints.deleteRecommendSize.replace('{id}', payload.id));
    if (response.status) return response.status;
    return false;
  },
  async updateRecommendSize (_, payload) {
    const payloadCopy = cloneDeep(payload);
    const id = payloadCopy.id;
    delete payloadCopy.id;
    return await http.put(apiEndpoints.updateRecommendSize.replace('{id}', id), payloadCopy);
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};

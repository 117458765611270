export default {
  table: {
    trader: '業者名',
    manager: '担当者',
    email: 'メールアドレス',
    tel: '電話番号',
  },
  form: {
    trader: '業者名',
    manager: '担当者名',
    email: 'メールアドレス',
    tel: '電話番号',
  },
};

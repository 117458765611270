export default {
  table: {
    product_image: '商品画像',
    product_name_display: '商品名(表示用)',
    product_name: '商品名',
    display: '表示',
    not_display: '非表示',
    distributor: '販売元',
    product_classification: '商品分類',
    size: 'サイズ選択',
    color: '色選択',
    price: '価格',
    sales_quantity: '販売数',
    display_hide: '表示・非表示',
    default: 'デフォルト',
  },
  label: {
    product_info: '商品情報',
    product_name: '商品名',
    product_name_display: '商品名（表示用）',
    product_no: '商品番号',
    distributor: '販売元',
    description: '商品説明',
    product_type: '商品種類',
    product_image: '商品画像',
    size_chart: 'サイズ表作成',
    optional_info: 'オプション情報',
    optional_edit: 'オプション編集',
    size: 'グループ',
    color: '色',
    option_value: 'オプション値',
    option_name_title: '{option_name}選択',
    recommend_size: 'マイサイズ設定',
  },
  button: {
    spec_select: 'スペック選択',
    automatic_combination: '自動組み合わせ',
    select_recommend_size: 'サイズ推奨選択',
    bulk_price: '価格を一括登録',
  },
  validates: {
    required_size: 'サイズの値を入力してください。',
    required_attr: '属性の値を入力してください。',
    option_value_duplicate: 'オプション値が重複しています。',
    require_minimum_one_option_value: 'オプション値を入力してください。',
    duplicate_variant: 'バリアントが重複しています。',
    image_type: 'JPG, JPEG, PNG形式の画像を選択してください。',
    image_size: 'ファイルのサイズが大きすぎます。5MB以内のファイルを指定してください。',
    required_seletbox: '1つの値を選択してください。',
  },
  dialog_mode: {
    confirm_on: '選択された行がすべてON状態になりますが、よろしいですか？',
    confirm_off: '選択された行がすべてOFF状態になりますが、よろしいですか？',
    title: '確認',
    button_yes: 'はい',
    button_no: 'いいえ',
  },
  dialog_change_option_or_option_value: {
    title: '通知',
    message: 'オプションまたはオプション値に変更があります。バリアントテーブルを更新する為に、「自動組み合わせ」ボタンをクリックしてください。',
    confirm_btn: 'OK',
  },
  diaglog_delete: {
    message: {
      delete: 'こちらの商品を削除してもよろしいですか。',
      in_collection: 'この商品は既にカテゴリに追加されています。',
    },
  },
  bulk_price_dialog: {
    title: '価格を一括登録',
    label: {
      price: '価格',
    },
    errors: {
      required_option_value: '{option}を選択してください。',
      price_half_size: '価格は半角数字で入力してください。',
      please_click_combine: '「自動組み合わせ」ボタンをクリックしてください。',
    },
  },
};

<template>
  <div class="header">
    <div class="header__wrapper">
      <div class="header__title">{{ headerTitle }}</div>
      <v-row align="center" justify="end" v-if="user">
        <v-menu
          bottom
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="btn__menu"
              elevation="0"
            >
              <span class="mr-2" >{{ user.name }}</span>
              <img src="@/assets/icons/ico_arrow_down.svg" alt="" />
            </v-btn>
          </template>

          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in menuItems"
                :key="i"
              >
                <v-list-item-title to="" @click="item.action">{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <div class="header__user">
          <img src="@/assets/images/img_avatar.png" alt="img_avatar">
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AdminHeader',
  data () {
    return {
      menuItems: [
        {
          title: this.$t('common.label.logout'),
          action: async () => {
            const logouted = await this.$store.dispatch('userStore/logoutAdmin');
            if (logouted) {
              this.$router.push({ name: 'login' });
            }
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: 'userStore/user',
    }),
    headerTitle () {
      return process.env.VUE_APP_HEADER_TITLE || this.$t('admin.title.header');
    },
  },
};
</script>

<style scoped></style>

export default {
  // auth
  login: '/admin/login',
  logout: '/admin/logout',

  // admin information
  adminInfo: '/admin/edit/{id}',

  // bank
  getBankById: '/common/bank/{id}',
  updateBank: '/common/bank/{id}',

  schoolList: '/schools',
  deleteSchool: '/schools/{id}',
  proposalToDeleteSchool: '/schools/{id}/proposal_delete',
  proposalToDeleteGroup: '/schools/{schoolId}/groups/{groupId}/proposal_delete',
  getSchool: '/schools/{id}',
  schoolExport: '/schools/download',
  schoolTypes: '/schools/types',
  groupGenerateCode: '/groups/gen-code',

  // supplier
  getListSupplier: '/products/suppliers',
  getSupplier: '/products/suppliers/{id}',
  createSupplier: '/products/suppliers',
  updateSupplier: '/products/suppliers/{id}',
  deleteSupplier: '/products/suppliers/{id}',
  exportSupplier: '/products/suppliers/exports',

  // spec
  getListSpec: '/products/specs',
  getProduct: '/products/{id}',
  getSpec: 'products/specs/{id}',
  createSpec: '/products/specs',
  deleteSpec: '/products/specs/{id}',
  cloneSpec: '/products/specs/{id}/clone',
  updateSpec: '/products/specs/{id}',

  // common - pages
  getPageBySlug: '/common/pages/{slug}',
  udpdatePageBySlud: '/common/pages/{slug}',

  // faq
  getListFaq: '/common/faqs',
  getFaqById: '/common/faqs/{id}',
  updateAllFaq: '/common/faqs/order',
  deleteFaqItem: '/common/faqs/{id}',
  updateFaqItem: '/common/faqs/{id}',
  createNewFaq: '/common/faqs',

  // product
  productTypes: '/products/types',
  productList: '/products',
  productCreate: '/products',
  productEdit: '/products/{id}',
  productExport: '/products/download',
  productUpdateStatus: '/products/status',
  productProposalToDelete: '/products/{id}/proposal_delete',
  productConfirmToDelete: '/products/{id}',

  // shipping patern
  getListShipping: '/ecommerce/shippings',
  getShipping: '/ecommerce/shippings/{id}',
  createShipping: '/ecommerce/shippings',
  updateShipping: '/ecommerce/shippings/{id}',
  deleteShipping: '/ecommerce/shippings/{id}',

  // upload images
  uploadImages: 'common/uploads',

  // collection
  getListCollection: '/products/collections',
  getCollection: '/products/collections/{id}',
  createCollection: '/products/collections',
  updateCollection: '/products/collections/{id}',
  deleteCollection: '/products/collections/{id}',
  exportCollection: '/products/collections/exports',
  changeDisplayCollection: '/products/collections/changes-display',
  changeDeliveryCollection: '/products/collections/changes-delivery',
  getListGroupSchool: '/products/collections/groups',
  getListShippingMethod: 'products/collections/shippings/fees',
  collectionProposalToDelete: '/products/collections/{id}/proposal_delete',
  collectionConfirmToDelete: '/products/collections/{id}',

  // recommend size
  getRecommendSizes: '/common/recommend-sizes',
  createRecommendSize: '/common/recommend-sizes',
  updateRecommendSize: '/common/recommend-sizes/{id}',
  deleteRecommendSize: '/common/recommend-sizes/{id}',

  // customer
  getListCustomer: '/user/customers',
  getCustomer: '/user/customers/{id}',
  updateCustomer: '/user/customers/{id}',
  exportCustomer: '/user/customers/exports',
  getPostalCode: '/postal-code/{postal-code}/address',
  deleteCustomer: '/user/customers/{id}',
  confirmCustomer: '/user/customers/{id}/confirm',

  // orders
  getListOrder: '/orders',
  exportOrder: '/orders/exports',
  updateStatus: '/orders/update/status',
  getOrderById: '/orders/{id}',
  updateOrder: '/orders/{id}',
  sendMail: 'orders/emails/sends',

  getListEmailLog: '/email-logs',
};

import Vue from 'vue';
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import i18n from '@/locales/i18n';
import msg from '@/locales/ja/msgCustomCommon';
import moment from 'moment';

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule],
    message: i18n.t('validate.messages.' + rule),
  });
  extend('phone_number', {
    validate (value) {
      const regPhoneNumber = /^0[0-9]{1,3}-[0-9]{2,4}-[0-9]{3,4}$/;
      return {
        valid: regPhoneNumber.test(value) && (value.length === 12 || value.length === 13),
      };
    },
    message: i18n.t('validate.messages.error_phone_number'),
  });
  extend('range', {
    validate (value, { min, max }) {
      return value >= min && value <= max;
    },
    params: ['min', 'max'],
    message: i18n.t('validate.messages.range_number'),
  });
});

setInteractionMode('passive');
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// custom validate
extend('isDateAfter', {
  params: ['endDate'],
  validate: (startDate, { endDate }) => { return moment(endDate).format('X') > moment(startDate).format('X'); },
  message: msg.is_date_after,
});
extend('isDateAfterOrEqual', {
  params: ['endDate'],
  validate: (startDate, { endDate }) => { return moment(endDate).format('X') >= moment(startDate).format('X'); },
  message: msg.is_date_after,
});

extend('greaterThanOrEqualTo', {
  params: ['min'],
  validate: (max, { min }) => { return max >= min; },
  message: msg.is_less_than_max,
});

extend('atLeastOne', {
  validate: (value) => { return value >= 1; },
  message: msg.at_least_one,
});

extend('half_width', {
  validate (value) {
    const regHalfWithAlphabet = /^[a-zA-Z0-9]+$/;
    return {
      valid: regHalfWithAlphabet.test(value),
    };
  },
  message: msg.type_haft_with,
});
extend('half_width_number', {
  validate (value) {
    const regHalfWithNumber = /^[-]?[0-9]+(\.[0-9]+)?$/;
    return {
      valid: regHalfWithNumber.test(value),
    };
  },
  message: msg.type_haft_with_number,
});

export default { };

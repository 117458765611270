import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';
import { cloneDeep } from 'lodash';

const getDefaultState = () => {
  return {};
};

const state = getDefaultState();
const getters = {};
const mutations = {};
const actions = {
  async getList (_, payload) {
    return await http.get(apiEndpoints.productList, payload);
  },
  async exportData (_, payload) {
    return await http.downloadPost(apiEndpoints.productExport, payload);
  },
  async getTypes () {
    return await http.get(apiEndpoints.productTypes);
  },
  async updateStatus (_, payload) {
    return await http.put(apiEndpoints.productUpdateStatus, payload);
  },
  async createProduct (_, payload) {
    return await http.post(apiEndpoints.productCreate, payload);
  },
  async updateProduct (_, payload) {
    const payloadCopy = cloneDeep(payload);
    const id = payloadCopy.id;
    delete payloadCopy.id;
    return await http.put(apiEndpoints.productEdit.replace('{id}', id), payloadCopy);
  },
  async getProduct (_, id) {
    return await http.get(apiEndpoints.getProduct.replace('{id}', id));
  },
  async proposalToDelete (_, id) {
    return await http.post(apiEndpoints.productProposalToDelete.replace('{id}', id));
  },
  async confirmToDelete (_, id) {
    return await http.delete(apiEndpoints.productConfirmToDelete.replace('{id}', id));
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};

import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';

const getDefaultState = () => {
  return {};
};

const state = getDefaultState();
const getters = {};
const mutations = {};
const actions = {
  async getBankById (_, id) {
    const bankResponse = await http.get(apiEndpoints.getBankById.replace('{id}', id));
    if (bankResponse.status && bankResponse.data) return bankResponse.data;
    return [];
  },
  async updateBank (_, payload) {
    return await http.put(apiEndpoints.updateBank.replace('{id}', payload.id), { content: payload.content });
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};

export default {
  label: {
    search: '検索',
    paging: '/ページ',
    require: '必須',
    create: '新規作成',
    logout: 'ログアウト',
    yen: '円',
    limit_perpage: '表示上限数をオーバーしたため、最初の1,000件のみ表示させています。',
    action: 'アクション',
    confirm_title: '確認',
  },
  button: {
    export_csv: 'CSV出力',
    create: '新規作成',
    keep: '保存',
    delete: '削除',
    cancel: 'キャンセル',
    add: '追加',
    complete: '完了',
    yes: 'はい',
    no: 'いいえ',
    confirm: '確認',
  },
  message: {
    success: '正常に保存しました。',
    error: 'エラーが発生しました。',
    no_data: 'データーがありません。',
    loading: 'ローディング。',
  },
  options: {
    all: 'すべて',
  },
  table: {
    nodata: '検索結果がありませんでした',
  },
  dialog_delete: {
    title: '項目の削除',
    message: 'こちらの項目を削除してもよろしいですか。',
  },
};

export default {
  title: {
    create: '銀行情報登録',
  },
  table: {
    infomation: '銀行情報',
  },
  form: {
    infomation: '銀行情報',
  },
};

import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';

const getDefaultState = () => {
};

const state = getDefaultState();
const getters = {
};
const mutations = {
};

const actions = {
  async getContentBySlug (_, slug) {
    const pageResponse = await http.get(apiEndpoints.getPageBySlug.replace('{slug}', slug));
    if (pageResponse.status && pageResponse.data) return pageResponse.data;
    return [];
  },
  async updateContentPage (_, payload) {
    const updateReponse = await http.put(apiEndpoints.udpdatePageBySlud.replace('{slug}', payload.slug), { content: payload.content });
    if (updateReponse.status && updateReponse.data) return updateReponse.data;
    return false;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};

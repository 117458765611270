import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';
import { cloneDeep } from 'lodash';

const getDefaultState = () => {
  return {
    form: {
      name: '',
      fees: [],
    },
    rowIndexFee: null,
  };
};

const state = getDefaultState();
const getters = {
  fees (state) {
    return state.form.fees;
  },
  rowIndexFee (state) {
    return state.rowIndexFee;
  },
  form (state) {
    return state.form;
  },
};
const mutations = {
  SET_ROW_INDEX_FEE (state, index) {
    state.rowIndexFee = index;
  },
  SET_FEES_BY_INDEX (state, { data, index }) {
    state.form.fees[index] = data;
  },
  SET_DATA_FORM (state, formData) {
    state.form = formData;
  },
  SET_DATA_FEES (state, feesData) {
    state.form.fees = feesData;
  },
};
const actions = {
  setFormData ({ commit }, formData) {
    commit('SET_DATA_FORM', formData);
  },
  setRowIndex ({ commit }, index) {
    commit('SET_ROW_INDEX_FEE', index);
  },
  setFeesData ({ commit }, feesData) {
    commit('SET_DATA_FEES', feesData);
  },
  async getListShipping (_, payload) {
    const response = await http.get(apiEndpoints.getListShipping, payload);
    if (response.status && response.data) return response.data;
    return [];
  },
  async deleteShipping (_, payload) {
    const response = await http.delete(apiEndpoints.deleteShipping.replace('{id}', payload.id));
    if (response.status) return response.status;
    return false;
  },
  async createShipping (_, payload) {
    return await http.post(apiEndpoints.createShipping, payload);
  },
  async getShippingById ({ commit }, idTransport) {
    const response = await http.get(apiEndpoints.getShipping.replace('{id}', idTransport));
    const { status, data } = response;
    if (status) {
      commit('SET_DATA_FORM', data.result);
    }
  },
  async updateShipping (_, payload) {
    const payloadCopy = cloneDeep(payload);
    const id = payloadCopy.id;
    delete payloadCopy.id;
    return await http.put(apiEndpoints.updateShipping.replace('{id}', id), payloadCopy);
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};

export default {
  input: {
    name: 'サイズ',
  },
  label: {
    submit: '保存する',
    clone_btn: '複製する',
    title: 'グループ',
    header: 'マイサイズ設定',
    boy: '男性',
    girl: '女性',
  },
  message: {
    require_size: 'サイズを入力してください。',
    is_up_value: 'サイズは左から右に昇順で入力してください。',
    require_priority: '優先度を入力してください。',
  },
};

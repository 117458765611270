const moment = require('moment');

export const formatDate = (value, format = 'YYYY/MM/DD') => {
  if (value) {
    return moment(value).format(format);
  }
  return null;
};

export const getImageUrl = (path) => {
  if (path) {
    return process.env.VUE_APP_FILE_URL + '/' + path.replace('public/', '');
  }

  return null;
};

export const scrollToInvalidInput = (errorClass = 'error-msg') => {
  const el = document.querySelector(`.${errorClass}`);
  if (el) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

export const replaceImgByDefault = (event) => {
  event.target.src = require('@/assets/images/img_unavailable.jpg');
  event.target.style = 'object-fit: cover !important';
};

export const sleep = (ms) => {
  return new Promise(
    resolve => setTimeout(resolve, ms),
  );
};

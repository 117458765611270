<template>
  <div>
    <component :is="layout">
      <router-view :key="$route.fullPath" />
    </component>
    <snack-bar></snack-bar>
  </div>
</template>

<script>
const DEFAULT_LAYOUT = 'init';
export default {
  computed: {
    layout () {
      return (this.$route.meta.layout || DEFAULT_LAYOUT) + '-layout';
    },
  },
};
</script>
<style lang="scss">
  @import "./assets/scss/app.scss";
</style>

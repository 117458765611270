export default {
  label: {
    list: 'FAQ設定',
    create: 'FAQ作成',
  },
  table: {
    question: '質問',
    answer: '回答',
  },
};

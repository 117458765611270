import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules/modules';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isShowLoading: false,
  },
  getters: {
    isShowLoading: state => state.isShowLoading,
  },
  mutations: {
    SET_IS_SHOW_LOADING: (state, payload) => {
      state.isShowLoading = payload;
    },
  },
  actions: {
    setIsShowLoading: ({ commit }, payload) => {
      commit('SET_IS_SHOW_LOADING', payload);
    },
  },
  modules,
  plugins: [createPersistedState({
    paths: ['userStore'],
    key: 'ec_data',
  })],
});

export default {
  table: {
    email: '送信先メールアドレス',
    type: 'タイプ',
    customer: '保護者',
    student: '生徒名',
    date_sent: '送信日',
    school: '団体',
    group: 'グループ',
    category: 'カテゴリ名',
  },
  labels: {
    search_range_date: '日付検索',
    email: 'メール',
    subject: '件名',
    content: '内容',
    type_0: '自動送信',
    type_1: '手動送信',
  },
  buttons: {

  },
};

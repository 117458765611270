<template>
  <div class="wrap_content">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AuthLayout',
  components: {},
};
</script>

<style lang="css" scoped>
.wrap_content {
  height: 100vh;
}
</style>

<template>
  <v-form
    @submit.prevent="submitSearchForm"
    class="form-search"
  >
    <v-text-field
      class="form-search__input"
      solo
      flat
      hide-details
      dense
      rounded
      autocomplete="off"
      :placeholder="$t(placeholder)"
      v-model.trim="searchInput"
      @change="onChangeInput"
    >
    </v-text-field>
    <v-btn
      class="form-search__btn"
      elevation="0"
      @click="submitSearchForm"
    >
      <svg-icon icon="search"></svg-icon>
    </v-btn>
  </v-form>
</template>
<script>
export default {
  name: 'SearchInput',
  props: {
    placeholder: { type: String, require: false, default: 'common.label.search' },
    value: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      searchInput: '',
      submitValue: '',
    };
  },
  created () {
    this.searchInput = this.value;
  },
  watch: {
    value (newValue) {
      this.searchInput = newValue;
    },
  },
  methods: {
    submitSearchForm () {
      this.$emit('submit', this.searchInput);
      this.submitValue = this.searchInput;
    },
    onChangeInput (value) {
      this.$emit('input', value);
    },
  },
};
</script>

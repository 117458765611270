import apiEndpoints from '@/services/apiEndpoints';
import http from '@/services/http';

export default {
  state: () => {},
  mutations: {},
  getters: {},
  actions: {
    async getListSupplier (_, payload) {
      const supplierResponse = await http.get(apiEndpoints.getListSupplier, payload);
      if (supplierResponse.status && supplierResponse.data) return supplierResponse.data;
      return [];
    },
    async deleteSupplier (_, payload) {
      const supplierResponse = await http.delete(apiEndpoints.deleteSupplier.replace('{id}', payload.id), {});
      if (supplierResponse.status) return supplierResponse.status;
      return false;
    },
    async exportSupplier (_, payload) {
      const supplierResponse = await http.downloadPost(apiEndpoints.exportSupplier, payload);
      if (supplierResponse.status) return supplierResponse;
      return false;
    },
    async createSupplier (_, payload) {
      const supplierResponse = await http.post(apiEndpoints.createSupplier, payload);
      if (supplierResponse.status && supplierResponse.data) return supplierResponse;
      return false;
    },
    async getSupplierById (_, idSupplier) {
      const supplierResponse = await http.get(apiEndpoints.getSupplier.replace('{id}', idSupplier), {});
      if (supplierResponse.status && supplierResponse.data) return supplierResponse.data;
      return false;
    },
    async updateSupplier (_, payload) {
      const id = payload.id;
      delete payload.id;
      const supplierResponse = await http.put(apiEndpoints.updateSupplier.replace('{id}', id), payload);
      if (supplierResponse.status) return supplierResponse;
      return false;
    },
  },
};

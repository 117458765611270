export default {
  table: {
    title: 'カテゴリ情報',
    type: '種別',
    category_name: 'カテゴリ名',
    group: '団体',
    target_group: '対象団体',
    display: '表示',
    display_period: '表示期間',
    description: '案内テキスト',
    shipping_pattern: '配送方法設定',
    shipping_pattern_btn: '配送パターン選択',
    image: 'トップ画面の写真',
    email_text: '注文完了メール挿入文',
    delivery_status: '配送状況',
  },
  options: {
    all: '種別',
  },
  shipping_pattern: {
    chooses_label: '配送方法設定',
    selection_method_label: '決済方法設定',
    method_1_name: 'クレジットカード',
    method_1_latina: 'credit_card',
    method_2_name: '銀行振込',
    method_2_latina: 'bank_transfer',
    popup: {
      search_input_label: '検索',
      name_label: '配送パターン名',
      method_label: '発送方法',
    },
  },
  product_selected_table: {
    title: '商品情報',
    drag_drop_label: '表示順序',
    name: '商品名',
    required: '必須',
    limit_range: '個数指定',
  },
  types: [
    {
      text: '一括',
      value: 1,
    },
    {
      text: '追加',
      value: 2,
    },
    {
      text: '通常',
      value: 3,
    },
  ],
  setting_reminder: {
    payment_due_date: '支払期日',
    payment_reminder: '支払リマインド',
    payment_reminder_latina: 'payment_reminder',
    fixed_text_payment: '支払期日の○日前',
    purchase_reminder: '注文リマインド',
    purchase_reminder_latina: 'purchase_reminder',
    fixed_text_purchase: '注文期限の○日前',
  },
  confirm_close_popup: {
    title: '確認',
    message: 'このまま閉じると選択された商品が反映されませんがよろしいですか？',
  },
  message_change_status: {
    display: '選択された行がすべて表示状態になりますが、よろしいですか？',
    not_display: '選択された行がすべて非表示状態になりますが、よろしいですか？',
    title: '確認',
  },
  button: {
    product_select: '商品選択',
  },
  diaglog_delete: {
    message: {
      delete: 'こちらのカテゴリを削除してもよろしいですか。',
      in_order: 'こちらのカテゴリの注文は既に存在します。',
    },
  },
};

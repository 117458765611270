import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';

const getDefaultState = () => {
  return {};
};

const state = getDefaultState();
const mutations = {};
const getters = {};

const actions = {
  async getListFaq (_) {
    const response = await http.get(apiEndpoints.getListFaq);
    if (response.status && response.data) return response.data;
    return [];
  },
  async getFaqById (_, id) {
    const response = await http.get(apiEndpoints.getFaqById.replace('{id}', id));
    if (response.status && response.data) return response.data;
    return false;
  },
  async deleteFaqItem (_, id) {
    const response = await http.delete(apiEndpoints.deleteFaqItem.replace('{id}', id));
    if (response.status) return response.status;
    return false;
  },
  async updateFaqItem (_, payload) {
    return await http.put(apiEndpoints.updateFaqItem.replace('{id}', payload.id), payload);
  },
  async updateAllFaq (_, payload) {
    const response = await http.put(apiEndpoints.updateAllFaq, payload);
    if (response.status && response.data) return response.data;
    return false;
  },
  async createFaq (_, payload) {
    return await http.post(apiEndpoints.createNewFaq, payload);
  },
};
export default {
  state,
  mutations,
  getters,
  actions,
};

<template>
  <div class="admin-pagination">
      <div
        class="v-pagination__navigation v-pagination__navigation--first"
        :disabled="isFirstDisabled"
        @click="currentPage = 1"
      >
        <v-icon
          large
          color="#666"
        >
          mdi-chevron-double-left
        </v-icon>
      </div>
      <v-pagination
        v-model="currentPage"
        :value="value"
        :length="length"
        :total-visible="totalVisible"
      ></v-pagination>
      <div
        class="v-pagination__navigation v-pagination__navigation--last"
        :disabled="isLastDisabled"
        @click="currentPage = length"
      >
        <v-icon
          large
          color="#666"
        >
          mdi-chevron-double-right
        </v-icon>
      </div>
  </div>
</template>

<script>
const DEFAULT_TOTAL_VISIBLE = 7;

export default {
  name: 'AdminPagination',
  props: {
    value: { type: Number, default: 0 },
    disabled: { type: Boolean, default: false },
    length: { type: Number, default: 0 },
    totalVisible: { type: [Number, String], default: DEFAULT_TOTAL_VISIBLE },
  },
  created () {
    this.currentPage = this.value;
  },
  data () {
    return {
      currentPage: 0,
    };
  },
  computed: {
    isFirstDisabled () {
      return this.currentPage <= 1;
    },
    isLastDisabled () {
      return this.currentPage >= this.length;
    },
  },
  watch: {
    currentPage: {
      handler (newVal) {
        this.$emit('input', this.currentPage);
      },
    },
    value: {
      handler (newVal) {
        this.currentPage = newVal;
      },
    },
  },
};
</script>
<style lang="scss">
.v-application {
  .admin-pagination {
    display: inline-flex;
    .v-pagination__navigation {
      &>li {
        &:first-child {
          .v-pagination__navigation {
            border-radius: 0;
          }
        }

        &:last-child {
          .v-pagination__navigation {
            border-radius: 0;
          }
        }
      }
      cursor: pointer;
      &[disabled] {
        opacity: .6;
        cursor: default;
      }
      &--first,
      &--last {
        background: #fff !important;
        min-width: 40px !important;
        padding: 0 5px !important;
        border-radius: 4px 0 0 4px !important;
        > * {
          font-size: 24px !important;
        }
      }
    }
    .v-pagination__item--active {
      background-color: #286AD7 !important;
      border-color: #286AD7 !important;
    }
  }
}
</style>

<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :class="dialogClass"
      :content-class="classList.join(' ')"
      width="auto"
      persistent
    >
      <v-card>
        <v-card-title class="dialog-header">
          <h3 class="dialog-header__title">
            <slot name="header"></slot>
          </h3>
          <v-btn
            @click="handleClose"
            class="btn__icon"
            :ripple="false"
            :elevation="0"
          >
            <svg-icon icon="close_circle"></svg-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="dialog-body">
          <slot></slot>
        </v-card-text>
        <v-card-actions class="dialog-footer">
          <slot name="footer"></slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const DIALOG_TYPE_MESSAGE = 0;
const DIALOG_TYPE_FORM = 1;

export default {
  name: 'AdminDialog',
  props: {
    classList: { type: [String, Array, Object], require: false, default: () => [] },
    dialogType: { type: Number, default: DIALOG_TYPE_MESSAGE },
    confirmClose: { type: Boolean, default: false },
  },
  data () {
    return {
      dialog: false,
    };
  },
  methods: {
    open () {
      this.dialog = true;
    },
    handleClose () {
      if (this.confirmClose) {
        this.$emit('confirmClose');
      } else {
        this.close();
      }
    },
    close () {
      this.$emit('close');
      this.dialog = false;
    },
    onlyClose () {
      this.dialog = false;
    },
  },
  computed: {
    dialogClass () {
      return {
        'minw-1000': this.dialogType === DIALOG_TYPE_FORM,
      };
    },
  },
};
</script>

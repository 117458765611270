import i18n from '@/locales/i18n';
const constant = {
  max_file_size: 5242880, // 5MB
  image_types: ['jpg', 'jpeg', 'png'],
  pagination_total_visible: 7,
  perpage_max: 1000,
  pagination_options: [
    {
      text: 20,
      value: 20,
    },
    {
      text: 50,
      value: 50,
    },
    {
      text: 100,
      value: 100,
    },
    {
      text: i18n.t('common.options.all'),
      value: 1000,
    },
  ],
  school_group_question_rules: [
    'no-rule',
    'only-half-width',
    'only-half-width-number',
  ],
  school_group_question_input_types: [
    'textarea',
    'radio',
    'checkbox',
    'select',
  ],
  custom_toolbar: [
    [{ font: [] }],
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike', 'link'],
    [
      { align: '' },
      { align: 'center' },
      { align: 'right' },
      { align: 'justify' },
    ],
    [{ header: 1 }, { header: 2 }],
    ['blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ color: [] }, { background: [] }],
    ['formula'],
    [{ direction: 'rtl' }],
    ['clean'],
  ],
  custom_toolbar_CKEditor: {
    toolbarGroups: [
      { name: 'styles', groups: ['styles'] },
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
      { name: 'forms', groups: ['forms'] },
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
      { name: 'links', groups: ['links'] },
      { name: 'insert', groups: ['insert'] },
      { name: 'colors', groups: ['colors'] },
      { name: 'tools', groups: ['tools'] },
      { name: 'others', groups: ['others'] },
    ],
    removeButtons: 'Image,Source,Cut,Copy,Paste,Maximize,About,PasteFromWord,PasteText,Undo,Redo,HorizontalRule,SpecialChar,Anchor,Scayt',
    language: 'ja',
    extraPlugins: 'justify,colorbutton',
  },
  CKEditor_Url: 'https://cdnjs.cloudflare.com/ajax/libs/ckeditor/4.20.0/ckeditor.js',
  checkbox_values: {
    true: 1,
    false: 0,
  },
  default_product_name: 'Default Title',
  prefectures: [
    '愛知県',
    '秋田県',
    '青森県',
    '千葉県',
    '愛媛県',
    '福井県',
    '福岡県',
    '福島県',
    '岐阜県',
    '群馬県',
    '広島県',
    '北海道',
    '兵庫県',
    '茨城県',
    '石川県',
    '岩手県',
    '香川県',
    '鹿児島県',
    '神奈川県',
    '高知県',
    '熊本県',
    '京都府',
    '三重県',
    '宮城県',
    '宮崎県',
    '長野県',
    '長崎県',
    '奈良県',
    '新潟県',
    '大分県',
    '岡山県',
    '沖縄県',
    '大阪府',
    '埼玉県',
    '滋賀県',
    '島根県',
    '静岡県',
    '栃木県',
    '徳島県',
    '東京都',
    '鳥取県',
    '富山県',
    '和歌山県',
    '山形県',
    '山口県',
    '山梨県',
  ],
  emaillogs: {
    types_options: [
      {
        text: '種別',
        value: null,
      },
      {
        text: '自動送信',
        value: 'auto',
      },
      {
        text: '手動送信',
        value: 'manual',
      },
    ],
  },
};

export default constant;

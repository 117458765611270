export default {
  title: {
    privacy_policy: 'プライバシーポリシー設定',
    terms_of_use: '利用規約設定',
    return_policy: '返品ポリシー設定',
    commercial_transaction_law: '特定商取引法設定',
    how_to_buy: '買い方設定',
    how_to_cancel_spam: '迷惑メール設定解除方法',
    external_characters: '外字を含む方はこちら',
  },
  form: {
    content: '内容',
  },
  msg: {
    require: '内容を入力してください。',
  },
};

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLangCommon from './en/common';
import enValidate from './en/validate';

import jaLangCommon from './ja/common';
import jaLangAuth from './ja/auth';
import jaOrder from './ja/order';
import jaProduct from './ja/product';
import jaCustomer from './ja/customer';
import jaSchool from './ja/school';
import jaSupplier from './ja/supplier';
import jaCollection from './ja/collection';
import jaValidate from './ja/validate';
import jaBank from './ja/bank';
import jaAdmin from './ja/admin';
import jaFaq from './ja/faq';
import jaSpec from './ja/spec';
import jaPage from './ja/page';
import jaShipping from './ja/shipping';
import jaSize from './ja/size';
import jaEmaiLogs from './ja/emaillog';

Vue.use(VueI18n);

const langs = {
  en: {
    common: enLangCommon,
    validate: enValidate,
  },
  ja: {
    common: jaLangCommon,
    auth: jaLangAuth,
    order: jaOrder,
    product: jaProduct,
    customer: jaCustomer,
    school: jaSchool,
    supplier: jaSupplier,
    collection: jaCollection,
    validate: jaValidate,
    bank: jaBank,
    admin: jaAdmin,
    faq: jaFaq,
    spec: jaSpec,
    page: jaPage,
    shipping: jaShipping,
    size: jaSize,
    emaillog: jaEmaiLogs,
  },
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'ja',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ja',
  messages: langs,
  silentFallbackWarn: true,
});

export default {
  form: {
    period: '期間',
    select_school: '団体状況',
  },
  button: {
    change_payment_status: '支払状況変更',
    change_delivery_status: '配送状況変更',
    payment_reminder: 'お支払いリマインド',
    send_email: '送信',
  },
  table: {
    order_id: '注文番号',
    order_datetime: '注文日時',
    school_name: '団体名',
    customer_name: '注文者',
    amount: '金額',
    payment_status: '支払',
    delivery_status: '配送',
    collection_name: 'カテゴリ名',
    student_name: '生徒名 ',
    remain_payment_days: '支払残日数',
  },
  labels: {
    no_due_date: '期日なし',
    remain_payment_days: '{day}日',
  },
  popup: {
    payment_status_title: '支払状況変更',
    delivery_status_title: '配送状況変更',
    list_status_label: '状況',
    change: '変更',
  },
  popup_success_send_email: {
    title: '送信完了',
    content: 'お支払いリマインドを送信しました。',
  },
  edit: {
    label: {
      fixed_text: '商品情報',
      product_cost: '商品計：',
      shipping_cost: '配送料：',
      total_amount: '合計：',
      bill: '支払情報',
      customer: '注文者',
      payment_status: '支払状況',
      payment_method: '支払方法：',
      payment_info: '支払情報',
      delivery_status: '配送状況',
      delivery_method: '配送方法：',
      delivery_info: '配送情報',
      postal_code: '郵便番号',
      prefecture: '都道府県',
      address_1: '住所',
      address_2: '建物など',
      phone_number: '電話番号',
    },
  },
  text_const: {
    credit_card: 'クレジットカード',
    credit_card_latina: 'credit_card',
    bank_transfer: '銀行振込',
    bank_transfer_latina: 'bank_transfer',
    payment_status_options: {
      all: '支払状況',
      status_1: '未払',
      status_2: '保留',
      status_3: '支払済',
      status_4: 'キャンセル',
    },
    delivery_status_options: {
      all: '配送状況',
      status_1: '未発送',
      status_2: '手配中',
      status_3: '完了',
      status_4: '保留',
    },
  },
  msg: {
    variant_not_existed: '在庫なし',
    confirm_send_email: 'リマインドメールを送信してもよろしいですか？',
  },
};

<template>
  <div class="sidebar">
    <v-list class="sidebar__list">
      <v-list-item
        v-for="(item, index) in sidebarItems"
        :key="index"
      >
        <div
          class="sidebar-item"
          :class="{
            'sidebar-item--active elevation-2': item.path && $route.fullPath.includes(item.path),
            'sidebar-item--border mb-2': item.has_border,
          }"
          @click="openPage(item.router_name)"
        >
          <svg-icon
            v-if="item.icon"
            :icon="item.icon"
            :actived="item.path && $route.fullPath.includes(item.path)"
          ></svg-icon>
          <div class="sidebar-item__name" :class="{'pl-4' : item.icon}">{{ item.title }}</div>
        </div>
        <div v-if="item.is_expand && item.children.length">
          <div v-for="(child, childIndex) in item.children" :key="childIndex">
            <div
              @click="openPage(child.router_name)"
              :to="{name: child.router_name}"
              v-html="child.title"
              class="sidebar-item__child"
              :class="{
                'sidebar-item__child--active elevation-2': child.router_name == $route.name || $route.fullPath.includes(child.path),
              }"
            ></div>
          </div>
        </div>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'AdminSidebar',
  data () {
    return {
      status: true,
      sidebarItems: [
        {
          icon: 'order',
          title: '注文',
          router_name: 'orders',
          path: '/orders',
        },
        {
          icon: 'customer',
          title: 'お客様',
          router_name: 'customers',
          path: '/customers',
          has_border: true,
        },
        {
          icon: 'product',
          title: '商品',
          router_name: 'products',
          path: '/products',
        },
        {
          icon: 'category',
          title: 'カテゴリ',
          router_name: 'collections',
          path: '/collections',
        },
        {
          icon: 'school',
          title: '団体',
          router_name: 'schools',
          path: '/schools',
        },
        {
          icon: 'delivery',
          title: '販売元',
          router_name: 'suppliers',
          path: '/suppliers',
        },
        {
          icon: 'emaillog',
          title: '督促メール送信BOX',
          router_name: 'email-logs',
          path: '/email-logs',
        },
        {
          icon: 'setting',
          title: '設定',
          router_name: null,
          path: null,
          is_expand: true,
          children: [
            {
              title: 'スペック設定',
              path: '/specs',
              router_name: 'specs',
            },
            {
              title: 'マイサイズ設定',
              path: '/mysize-setting',
              router_name: 'mysize_setting',
            },
            {
              title: '管理者情報設定',
              path: '/admin/edit-information',
              router_name: 'edit_admin',
            },
            {
              title: 'FAQ設定',
              path: '/faqs',
              router_name: 'faqs',
            },
            {
              title: '配送情報設定',
              path: '/transports',
              router_name: 'transports',
            },
            {
              title: '銀行情報登録',
              path: '/banks',
              router_name: 'banks',
            },
            {
              title: 'プライバシーポリ<br>シー設定',
              router_name: 'privacy-policy',
            },
            {
              title: '利用規約設定',
              router_name: 'terms-of-use',
            },
            {
              title: '返品ポリシー設定',
              router_name: 'return-policy',
            },
            {
              title: '特定商取引法設定',
              router_name: 'commercial-transaction-law',
            },
            {
              title: '買い方設定',
              router_name: 'how-to-buy',
            },
            {
              title: '迷惑メール設定解除方法',
              router_name: 'how-to-cancel-spam',
            },
            {
              title: '外字を含む方はこちら',
              router_name: 'external-characters',
            },
          ],
        },
      ],
    };
  },
  methods: {
    openPage (routerName) {
      if (!routerName) {
        return false;
      }
      if (routerName && routerName !== this.$route.name) {
        this.$router.push({ name: routerName });
      }
    },
  },
};
</script>

<style scoped></style>

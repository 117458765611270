import moment from 'moment';
const DATE_FORMAT_TIME = 'YYYY/MM/DD HH:mm';
export function firstImageProduct (images) {
  if (images && images[0] && images[0].path) {
    return process.env.VUE_APP_FILE_URL + '/' + images[0].path.replace('public/', '');
  }
  return '';
}

export function formatUrlImage (image) {
  if (image) {
    return process.env.VUE_APP_FILE_URL + '/' + image.replace('public/', '');
  }
  return '';
}
export function formatDateTime (value) {
  if (value) {
    return moment(value).format(DATE_FORMAT_TIME);
  }
}

<template>
  <v-app id="main-app">
    <admin-header></admin-header>
    <v-main id="content-wrapper" class="d-flex flex-column">
      <admin-sidebar></admin-sidebar>
      <v-container
        fluid
        class="main-container"
      >
        <router-view :key="$route.fullPath"></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AdminHeader from '@/layouts/admin/Header.vue';
import AdminSidebar from '@/layouts/admin/Sidebar.vue';

export default {
  name: 'AdminLayout',
  components: {
    AdminHeader,
    AdminSidebar,
  },
  created () {
    //
  },
  methods: {
    //
  },
};
</script>

<style>

</style>

export default {
  table: {
    id: 'ID',
    parent_name: '保護者',
    student_name: '生徒',
    phone_number: '電話番号',
    action: 'アクション',
  },
  detail: {
    title: '保護者情報',
    last_name: '姓',
    first_name: '名',
    kana_last_name: 'セイ',
    kana_first_name: 'メイ',
    postal_code: '郵便番号',
    prefecture: '都道府県',
    address_1: '住所',
    address_2: '建物など',
    phone_number: '電話番号',
    student: {
      student_title: '生徒情報',
      birthday: '生年月日',
      gender: '性別',
      school: '団体',
      code: '受験番号',
    },
  },
  genders: {
    female: '男',
    male: '女',
  },
  dialog_delete: {
    title: '項目の削除',
    message: 'こちらのお客様を削除してもよろしいですか。',
  },
  dialog_order_exist: {
    title: '項目の削除',
    message: 'こちらのお客様の注文は既に存在します。',
  },
  button: {
    become_confirmed: '確認用に切り替える',
    return_normal: '元に戻す',
  },
};

export default {
  table: {
    school_name: '団体',
    classfication: '分類',
    email: 'メールアドレス',
    tel: '電話番号',
    action: 'アクション',

    group_name: 'グループ名',
    expired_date: '有効期限',
    authentication_code: '認証コード',
    input_type: '入力タイプ',
    content: '質問文',
    value: '値',
    required: '必須',
  },
  dialog_delete: {
    title: '項目の削除',
    message: 'こちらの項目を削除してもよろしいですか。',
    has_collection: '所属するカテゴリが存在するため削除できません。',
  },
  dialog_group: {
    title: 'フォーム情報入力',
    button: '完了',
  },
  label: {
    organization_name: '団体名',
    classification: '分類',
    email: 'メールアドレス',
    phone_number: '電話番号',
    group_info: 'グループ情報',
    input_type: '入力タイプ',
    content: '質問文',
    value: '値',
    required: '必須',
    choice_list: '選択肢一覧',
    rule: 'ルール',
    group_message: '団体固有質問設定',
    by_category: '分類ごと',
  },
  rules: {
    'no-rule': '制限なし',
    'only-half-width': '半角のみ',
    'only-half-width-number': '半角数字のみ',
  },
  input_types: {
    textarea: '入力',
    radio: 'ラジオ',
    checkbox: 'チェックボックス',
    select: '選択',
  },
  button: {
    add_group: 'グループ追加',
  },
  dialog_mode: {
    title: 'メッセージ',
    hidden_mode_message: '団体を非表示状態に変更しました。',
    show_mode_message: '団体を表示状態に変更しました。',
  },
  dialog_delete_message: {
    group: 'こちらのグループを削除してもよろしいでしょうか。',
    form_item: 'こちらのフォームアイテムを削除してもよろしいでしょうか。',
  },
};

import apiEndpoints from '@/services/apiEndpoints';
import http from '@/services/http';

export default {
  state: () => {},
  mutations: {},
  getters: {},
  actions: {
    async getListCustomer (_, payload) {
      const customerResponse = await http.get(apiEndpoints.getListCustomer, payload);
      if (customerResponse.status && customerResponse.data) return customerResponse.data;
      return [];
    },
    async exportCustomer (_, payload) {
      const customerResponse = await http.downloadPost(apiEndpoints.exportCustomer, payload);
      return customerResponse;
    },
    async getCustomerById (_, idCustomer) {
      const customerResponse = await http.get(apiEndpoints.getCustomer.replace('{id}', idCustomer), {});
      if (customerResponse.status && customerResponse.data) return customerResponse.data;
      return false;
    },
    async updateCustomer (_, payload) {
      const id = payload.id;
      delete payload.id;
      const customerResponse = await http.put(apiEndpoints.updateCustomer.replace('{id}', id), payload);
      if (customerResponse.status) return customerResponse;
      return false;
    },
    async getInfoPostalCode (_, payload) {
      const res = await http.get(apiEndpoints.getPostalCode.replace('{postal-code}', payload.postal_code));
      const { status, data } = res;
      if (status) return data.result;
    },
    async deleteCustomer (_, payload) {
      const res = await http.delete(apiEndpoints.deleteCustomer.replace('{id}', payload.customer_id));
      const { status, data } = res;
      if (status) return data.result;
    },
    async beComeTheConfirmedCustomer (_, payload) {
      const res = await http.post(apiEndpoints.confirmCustomer.replace('{id}', payload.customer_id));
      const { status, data } = res;
      if (status) return data.result;
    },
    async beComeTheNormalCustomer (_, payload) {
      const res = await http.delete(apiEndpoints.confirmCustomer.replace('{id}', payload.customer_id));
      const { status, data } = res;
      if (status) return data.result;
    },
  },
};

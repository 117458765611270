import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';
import { cloneDeep } from 'lodash';

const getDefaultState = () => {
  return {};
};

const state = getDefaultState();
const getters = {};
const mutations = {};
const actions = {
  async getSchoolList (_, payload) {
    return await http.get(apiEndpoints.schoolList, payload);
  },
  async exportData (_, payload) {
    return await http.downloadPost(apiEndpoints.schoolExport, payload);
  },
  async getSchoolTypes () {
    return await http.get(apiEndpoints.schoolTypes);
  },
  async deleteSchool (_, id) {
    return await http.delete(apiEndpoints.deleteSchool.replace('{id}', id));
  },
  async generateGroupCode () {
    return await http.get(apiEndpoints.groupGenerateCode);
  },
  async createSchool (_, payload) {
    return await http.post(apiEndpoints.schoolList, payload);
  },
  async updateSchool (_, payload) {
    const payloadCopy = cloneDeep(payload);
    const id = payloadCopy.id;
    delete payloadCopy.id;
    return await http.put(apiEndpoints.getSchool.replace('{id}', id), payloadCopy);
  },
  async getSchool (_, id) {
    return await http.get(apiEndpoints.getSchool.replace('{id}', id));
  },
  async proposalToDeleteSchool (_, id) {
    return await http.post(apiEndpoints.proposalToDeleteSchool.replace('{id}', id));
  },
  async proposalToDeleteGroup (_, { schoolId, groupId }) {
    return await http.post(apiEndpoints.proposalToDeleteGroup.replace('{schoolId}', schoolId).replace('{groupId}', groupId));
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};

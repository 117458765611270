export default {
  title: {
    create: 'スペック作成',
    list: 'スペック設定',
    edit: '服',
  },
  button: {
    add_option: 'オプション追加',
  },
  label: {
    category_name: '商品分類',
    option_name: 'オプション名',
    option_value: 'オプション値',
    spec_label: '設定されているスペック',
    action_label: '操作',
  },
};

export default {
  title: {
    edit: '管理者情報',
    header: 'SCHOOLPARTNER',
  },
  message: {
    notification: '＊「パスワード」と「パスワード（確認）」項目にデータが入力されている時のみにパスワードの値が更新されます。',
  },
  table: {
    id: 'ID',
    password: 'パスワード',
    password_confirm: 'パスワード（確認用）',
    name: '担当者名',
    email: 'メールアドレス',
    phone: '電話番号',
    address: '住所',
  },
  form: {
    id: 'ID',
    password: 'パスワード',
    password_confirm: 'パスワード（確認用）',
    name: '担当者名',
    email: 'メールアドレス',
    phone: '電話番号',
    address: '住所',
  },
};

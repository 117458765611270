import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';

const getDefaultState = () => {
  return {};
};

const state = getDefaultState();
const getters = {};
const mutations = {};

const actions = {
  async getAdminInformation (_, id) {
    const response = await http.get(apiEndpoints.adminInfo.replace('{id}', id));
    if (response.status && response.data) return response.data;
    return [];
  },
  async updateAdminInformation (_, payload) {
    if (payload.form.password === '' && payload.form.password_confirmation === '') {
      delete payload.form.password;
      delete payload.form.password_confirmation;
    }
    return await http.put(apiEndpoints.adminInfo.replace('{id}', payload.id), payload.form);
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};

import apiEndpoints from '@/services/apiEndpoints';
import http from '@/services/http';

export default {
  state: () => {},
  mutations: {},
  getters: {},
  actions: {
    async getListSpec (_, payload) {
      const specResponse = await http.get(apiEndpoints.getListSpec, payload);
      if (specResponse.status && specResponse.data) return specResponse.data;
      return [];
    },
    async getSpecById (_, id) {
      const specResponse = await http.get(apiEndpoints.getSpec.replace('{id}', id), {});
      if (specResponse.status && specResponse.data) return specResponse.data;
      return false;
    },
    async createSpec (_, payload) {
      const specResponse = await http.post(apiEndpoints.createSpec, payload);
      if (specResponse.status && specResponse.data) return specResponse;
      return [];
    },
    async deleteSpec (_, id) {
      const specResponse = await http.delete(apiEndpoints.deleteSpec.replace('{id}', id), {});
      if (specResponse.status) return specResponse.status;
      return false;
    },
    async cloneSpec (_, id) {
      const specResponse = await http.get(apiEndpoints.cloneSpec.replace('{id}', id), {});
      if (specResponse.status && specResponse.data) return specResponse.data;
      return [];
    },
    async updateSpec (_, payload) {
      const id = payload.id;
      delete payload.id;
      const specResponse = await http.put(apiEndpoints.updateSpec.replace('{id}', id), payload);
      if (specResponse.status) return specResponse;
      return false;
    },
  },
};

import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';

const getDefaultState = () => {
  return {
    user: {},
    token: '',
  };
};

const state = getDefaultState();
const getters = {
  user (state) {
    return state.user;
  },
  token (state) {
    return state.token;
  },
};
const mutations = {
  SET_USER (state, { token, user }) {
    state.token = token;
    state.user = user;
  },
  UNSET_USER (state) {
    state.token = '';
    state.user = {};
  },
};
const actions = {
  async loginAdmin ({ commit }, payload) {
    const resLogin = await http.post(apiEndpoints.login, payload);
    const { status, data } = resLogin;
    if (status) {
      const { token, user, status: loginStatus } = data.result;
      if (loginStatus === false) {
        return false;
      }
      commit('SET_USER', {
        token,
        user,
      });
      return true;
    } else {
      return false;
    }
  },
  async logoutAdmin ({ commit }, payload) {
    await http.post(apiEndpoints.logout);
    commit('UNSET_USER');
    return true;
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};
